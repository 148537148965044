// src\html\js\show-tour.js

$(document).ready(function() {

  var cookie;
  var haveTourModal = (document.getElementById('tourModal') !== null);
  var haveCookieBanner = (document.getElementById('cookieBanner') !== null);

  // are we showing the tour?
  if (haveTourModal && !haveCookieBanner) {
    showTour();
  }

  // are we showing the cookie policy?
  if (haveCookieBanner) {
    showCookieBanner();
  }

  function showTour() {
    //tour new feature modal
    $('#tourModal').modal('show');
    // set cookie
    cookie = 'reckitt-tour-shown=true;path=/;max-age=31536000;samesite=lax';
    document.cookie = cookie;
    //close #tourModal from US-Hygiene, CA-Hygiene, US-Health or CA-Health button/link clicks
    $('#usHY' || '#caHY' || '#usHE' || '#caHE').click(function() {
        $('#tourModal').modal('hide');
    });
  } // showTour()

  function showCookieBanner() {
    //static backdrop forces user to click modal button options provided
    var $cookieBanner = $('#cookieBanner');
    $cookieBanner.modal({backdrop: 'static', keyboard: false});
    // set cookie
    cookie = 'reckitt-cookie-banner=v1;path=/;max-age=31536000;samesite=lax';
    document.cookie = cookie;
    // close here to show tour if necessary
    $cookieBanner.on('hidden.bs.modal', function() {
      if (haveTourModal) {
        showTour();
      }
  });
  } // showCookieBanner()

});